import { Typography, useTheme } from '@mui/material'
import { FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

interface Props {
  bgcolor?: string
  squareCorners?: boolean
  tag: string
}

const Tag: React.FC<Props> = (props) => {
  const theme = useTheme()
  const { tag, bgcolor = theme.palette.secondary.main, squareCorners = false } = props

  const optionsParams = squareCorners ? { borderRadius: 0 } : {}

  return (
    <FlexRow paper {...optionsParams} paddingX={0.5} bgcolor={bgcolor} color={theme.palette.getContrastText(bgcolor)}>
      <Typography variant="caption">{tag}</Typography>
    </FlexRow>
  )
}

export default Tag
