import { CoinUserStatsXyoJson } from '@xylabs/sdk-coin-js'
import { isAuthenticated, useAuthUser } from '@xylabs/sdk-coin-react'
import { useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import { AxiosError } from 'axios'
import { useState } from 'react'

import { useApi } from '../../../Contexts'

interface CoinUserStatsResult {
  _admin?: boolean
  stats?: CoinUserStatsXyoJson[]
}

const useUserStatsXyo = (start: number, end: number, sanitize = false) => {
  const user = useAuthUser()
  const authenticated = isAuthenticated()
  const api = useApi(true)

  const [statsXyo, setStatsXyo] = useState<CoinUserStatsResult>()
  const [error, setError] = useState<AxiosError>()
  const [refreshCount, setRefreshCount] = useState(0)

  useAsyncEffect(
    async (mounted) => {
      if (user && api && authenticated) {
        try {
          if (mounted()) {
            if (error) setError(undefined)
            setStatsXyo(undefined)
          }
          const result = await (refreshCount === 0
            ? api.getUserStatsXyo(start, end, sanitize)
            : api.fetchUserStatsXyo(start, end, sanitize))
          if (mounted()) {
            setStatsXyo(result)
          }
        } catch (ex) {
          const error = ex as AxiosError
          if (mounted()) {
            setError(error)
          }
        }
      }
    },
    [api, user, authenticated, sanitize, refreshCount]
  )

  const refreshStatsXyo = () => {
    setRefreshCount(refreshCount + 1)
  }

  return { error, refreshStatsXyo, statsXyo }
}

export default useUserStatsXyo
