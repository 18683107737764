import { CoinSweepstakeHistoryJson } from '@xylabs/sdk-coin-js'
import { useAuthUser } from '@xylabs/sdk-coin-react'
import { FlexGrowCol, useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import { useState } from 'react'

import { useApi } from '../../../Contexts'
import BasePage from '../../BasePage'
import { SweepstakesDashboard } from '../Components'

const Page: React.FC = () => {
  const api = useApi(true)
  const user = useAuthUser()

  const breadcrumbs = [user?.displayName ?? 'User', 'Sweepstakes']

  const [sweepstakes, setSweepstakes] = useState<CoinSweepstakeHistoryJson[]>()

  useAsyncEffect(
    async (mounted) => {
      if (api?.authenticated) {
        const sweepstakes = await api.getUserSweepstakes()
        if (mounted()) {
          setSweepstakes(sweepstakes)
        }
      }
    },
    [api?.authenticated]
  )

  return (
    <BasePage devOnly authOnly container="xl" breadcrumbs={breadcrumbs} beta>
      <FlexGrowCol marginY={2} alignItems="stretch" justifyContent="flex-start" busy={sweepstakes === undefined}>
        <SweepstakesDashboard />
      </FlexGrowCol>
    </BasePage>
  )
}

export default Page
