import { Link, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

const WhatIsCoin: React.FC = () => {
  return (
    <FlexCol alignItems="flex-start" margin={2}>
      <FlexRow justifyContent="flex-start" marginY={1}>
        <Typography variant="subtitle1">What is app.coinapp.co?</Typography>
      </FlexRow>
      <FlexRow justifyContent="flex-start" marginY={1}>
        <Typography variant="subtitle2">
          app.coinapp.co is COIN’s desktop app. While you won’t be able to do all the regular things that you can do in
          the mobile COIN app, we’ll have some other fun features, opportunities, and activities here you can explore
          soon.
        </Typography>
      </FlexRow>
      <FlexRow justifyContent="flex-start" marginY={1}>
        <Typography variant="subtitle2">
          The full website is currently in alpha (meaning we’re just testing in internally with the team for now!) but
          we look forward to offering an open beta soon. In the meantime, be sure to{' '}
          <Link href="https://coinapp.co/download">download</Link> COIN from your app store, and start playing.
        </Typography>
      </FlexRow>
      <FlexRow justifyContent="flex-start" marginY={1}>
        <Typography variant="subtitle2">
          To learn more about the COIN App, visit <Link href="https://coinapp.co">coinapp.co</Link>.
        </Typography>
      </FlexRow>
      <FlexRow justifyContent="flex-start" marginY={1}>
        <Typography variant="subtitle2">
          If you are an existing user, visit <Link href="https://my.coinapp.co">my.coinapp.co</Link> to access your
          account.
        </Typography>
      </FlexRow>
    </FlexCol>
  )
}

export default WhatIsCoin
