import { Typography } from '@mui/material'
import { CoinSweepstakeJson } from '@xylabs/sdk-coin-js'
import { FlexGrowCol, FlexRow, useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import React, { useState } from 'react'

import { useApi } from '../../Contexts'
import BasePage from '../BasePage'
import { JsonViewer } from './Components'

const GraphJsonPage: React.FC = () => {
  const api = useApi()
  const [sweepstakes, setSweepstakes] = useState<CoinSweepstakeJson[]>()
  const [calltime, setCalltime] = useState<number>()

  useAsyncEffect(
    async (mounted) => {
      if (api) {
        let calltime = Date.now()
        const sweepstakes = await api.getSweepstakes()
        calltime = Date.now() - calltime
        if (mounted()) {
          setCalltime(calltime)
          setSweepstakes(sweepstakes)
        }
      }
    },
    [api]
  )

  return (
    <BasePage title="Home Graph Json" devOnlyTo="/" container="xl" localAsDev>
      <FlexGrowCol marginY={2} alignItems="stretch">
        <FlexRow justifyContent="flex-start" marginY={1}>
          <Typography variant="h4">{`Available Offers (GraphQL) ${calltime ? `[${calltime}ms]` : ''}`}</Typography>
        </FlexRow>
        <FlexGrowCol alignItems="stretch" marginTop={2} busy={!sweepstakes}>
          {sweepstakes ? <JsonViewer open={!!sweepstakes} json={{ ...sweepstakes }} /> : null}
        </FlexGrowCol>
      </FlexGrowCol>
    </BasePage>
  )
}

export default GraphJsonPage
