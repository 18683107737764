import { ToolbarProps } from '@mui/material'
import { AppBarEx, AppBarExProps } from '@xyo-network/sdk-xyo-react-js'
import { ReactElement } from 'react'

import SystemToolBar from './SystemToolbar'

interface Props extends AppBarExProps {
  hideSystemToolbar?: boolean
  toolbar?: ReactElement<ToolbarProps>
}

const Header: React.FC<Props> = (props) => {
  const { toolbar, hideSystemToolbar, ...rootProps } = props
  return (
    <AppBarEx
      container="xl"
      contextToolbar={toolbar}
      systemToolbar={!hideSystemToolbar ? <SystemToolBar /> : undefined}
      {...rootProps}
    />
  )
}

export default Header
