import { Container } from '@mui/material'
import { FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import BasePage from '../BasePage'
import rulesImage from './img/SweepstakesRules.png'

const RulesPage: React.FC = () => {
  return (
    <BasePage>
      <Container>
        <FlexRow marginY={8}>
          <img src={rulesImage} alt="Sweepstakes Rules" width="100%" height="auto" />
        </FlexRow>
      </Container>
    </BasePage>
  )
}

export default RulesPage
