/* eslint-disable @typescript-eslint/no-explicit-any */
export const DEFAULT_CVC_LENGTH = 3
export const DEFAULT_ZIP_LENGTH = 5
export const DEFAULT_CARD_FORMAT = /(\d{1,4})/g
export const CARD_TYPES = [
  {
    cvcLength: 4,
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    maxCardNumberLength: 15,
    startPattern: /^3[47]/,
    type: 'amex',
  },
  {
    cvcLength: 4,
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    maxCardNumberLength: 15,
    startPattern: /^3[47]/,
    type: 'american-express',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 16,
    startPattern: /^5019/,
    type: 'dankort',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 19,
    startPattern: /^(384100|384140|384160|606282|637095|637568|60(?!11))/,
    type: 'hipercard',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 14,
    startPattern: /^(36|38|30[0-5])/,
    type: 'dinersclub',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 16,
    startPattern: /^(6011|65|64[4-9]|622)/,
    type: 'discover',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 16,
    startPattern: /^35/,
    type: 'jcb',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 19,
    startPattern: /^(6706|6771|6709)/,
    type: 'laser',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 19,
    startPattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
    type: 'maestro',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 16,
    startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    type: 'mastercard',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    luhn: false,
    maxCardNumberLength: 19,
    startPattern: /^62/,
    type: 'unionpay',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 16,
    startPattern: /^4(026|17500|405|508|844|91[37])/,
    type: 'visaelectron',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 16,
    startPattern:
      /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|3[0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8])|9([2-6][0-9]|7[0-8])|541|700|720|901)|651652|655000|655021)/,
    type: 'elo',
  },
  {
    cvcLength: DEFAULT_CVC_LENGTH,
    format: DEFAULT_CARD_FORMAT,
    maxCardNumberLength: 19,
    startPattern: /^4/,
    type: 'visa',
  },
]

export const getCardTypeByValue = (value: any) => CARD_TYPES.filter((cardType) => cardType.startPattern.test(value))[0]
export const getCardTypeByType = (type: string) => CARD_TYPES.filter((cardType) => cardType.type === type)[0]
export const hasCardNumberReachedMaxLength = (currentValue: any, currentValueLength: number) => {
  const cardType = getCardTypeByValue(currentValue)
  return cardType && currentValueLength >= cardType.maxCardNumberLength
}
export const hasCVCReachedMaxLength = (type: string, currentValueLength: number) => {
  const cardType = getCardTypeByType(type)
  if (!cardType) {
    return currentValueLength >= DEFAULT_CVC_LENGTH
  }
  return currentValueLength >= cardType.cvcLength
}
export const hasZipReachedMaxLength = (type: string, currentValueLength: number) =>
  currentValueLength >= DEFAULT_ZIP_LENGTH
export const formatCardNumber = (cardNumber: { match: (arg0: RegExp) => any[]; split: (arg0: string) => any[] }) => {
  const cardType = getCardTypeByValue(cardNumber)
  if (!cardType) return (cardNumber.match(/\d+/g) || []).join('')
  const { format } = cardType
  if (format.global) {
    return cardNumber.match(format).join(' ')
  }
  const execResult = format.exec(cardNumber.split(' ').join(''))
  if (execResult) {
    return execResult
      .splice(1, 3)
      .filter((x) => x)
      .join(' ')
  }
  return cardNumber
}
export const formatCvc = (cvc: string) => {
  return (cvc.match(/\d+/g) || []).join('')
}
export const formatExpiry = (event: { nativeEvent: { data: any }; target: { value: string } }) => {
  const eventData = event.nativeEvent && event.nativeEvent.data
  const prevExpiry = event.target.value.split(' / ').join('/')

  if (!prevExpiry) return null
  let expiry: any = prevExpiry
  if (/^[2-9]$/.test(expiry)) {
    expiry = `0${expiry}`
  }

  if (prevExpiry.length === 2 && +prevExpiry > 12) {
    const [head, ...tail] = prevExpiry
    expiry = `0${head}/${tail.join('')}`
  }

  if (/^1[/-]$/.test(expiry)) {
    return '01 / '
  }

  expiry = expiry.match(/(\d{1,2})/g) || []
  if (expiry.length === 1) {
    if (!eventData && prevExpiry.includes('/')) {
      return expiry[0]
    }
    if (/\d{2}/.test(expiry)) {
      return `${expiry[0]} / `
    }
  }
  if (expiry.length > 2) {
    const [, month, year] = expiry.join('').match(/^(\d{2}).*(\d{2})$/) || []
    return [month, year].join(' / ')
  }
  return expiry.join(' / ')
}
export const isHighlighted = () => window.getSelection()?.type === 'Range'
