import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import { Button, Typography } from '@mui/material'
import { CoinFeedbackSubmissionJson, FeedbackOffers, FeedbackOfferSku } from '@xylabs/sdk-coin-js'
import { useAuthUser } from '@xylabs/sdk-coin-react'
import { assertEx } from '@xyo-network/sdk-xyo-js'
import { FlexGrowCol, FlexRow, MessageDialog } from '@xyo-network/sdk-xyo-react-js'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPlugInImageResize from 'filepond-plugin-image-resize'
import FilePondPlugInImageTransform from 'filepond-plugin-image-transform'
import React, { useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { match, Redirect } from 'react-router-dom'

import { useApi } from '../../Contexts'
import BasePage from '../BasePage'

registerPlugin(
  FilePondPlugInImageTransform,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPlugInImageResize
)

interface Props {
  match: match<{ id: FeedbackOfferSku }>
}

const Page: React.FC<Props> = (props) => {
  const { match } = props
  const sku = match?.params?.id
  const [files, setFiles] = useState<File[]>([])
  const [busy, setBusy] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState<string>()
  const [thankyouOpen, setThankyouOpen] = useState(false)
  const api = useApi()
  const user = useAuthUser()

  const offer = FeedbackOffers[sku]

  const submitFeedback = async (files: Buffer[]) => {
    const feedback: CoinFeedbackSubmissionJson = {
      email: assertEx(user?.email),
      files,
      sku,
      uid: user?.uid,
    }
    return await api?.submitFeedback(feedback)
  }

  const resizeSmall = (transforms: { resize: unknown }) => {
    transforms.resize = {
      size: {
        height: 640,
        width: 640,
      },
    }
    return transforms
  }

  const onCompleteClicked = async () => {
    setBusy(true)
    try {
      const fileBuffers: Buffer[] = []
      if (files && files.length > 0) {
        for (const file of files) {
          console.log(`file: ${JSON.stringify(file.size)}`)
          fileBuffers.push(Buffer.from(await file.arrayBuffer()))
        }
        await submitFeedback(fileBuffers)
        setThankyouOpen(true)
      } else {
        setError('Please add an image')
      }
    } catch (ex) {
      console.log(ex)
      setError('An error occured. Please try again later.')
    } finally {
      setBusy(false)
    }
  }

  const onThankyouClose = () => {
    setThankyouOpen(false)
    setCompleted(true)
  }

  if (completed || !offer) {
    return <Redirect to="/" />
  }

  return (
    <BasePage container="md" authOnly>
      <FlexRow paper marginY={2} width="100%">
        <FlexGrowCol alignItems="stretch" justifyContent="flex-start" marginY={2} marginX={1} busy={busy}>
          <FlexRow>
            <Typography variant="h3">{offer.title}</Typography>
          </FlexRow>
          {offer.reward.coin ? (
            <FlexRow>
              <Typography variant="h5">{`Reward: ${offer.reward.coin} COIN`}</Typography>
            </FlexRow>
          ) : null}
          {offer.reward.sweepstakes ? (
            <FlexRow>
              <Typography variant="h5">{`Reward: ${offer.reward.sweepstakes} Entries`}</Typography>
            </FlexRow>
          ) : null}
          <FlexRow>
            <Typography variant="body1">{offer.instructions}</Typography>
          </FlexRow>
          <FilePond
            allowMultiple={false}
            onremovefile={() => setFiles([])}
            labelIdle={'Drag & Drop one file or <span class="filepond--label-action">Browse</span>'}
            maxFiles={1}
            allowImageTransform={true}
            allowImageExifOrientation={true}
            allowProcess={true}
            imageTransformVariants={{ resizeSmall }}
            name="files"
            server={{
              process: (fieldName, file, metadata, load) => {
                setFiles([file as File])
                // simulates uploading a file
                setTimeout(() => {
                  load(Date.now().toString())
                }, 1000)
              },
            }}
          />
          {error ? (
            <FlexRow>
              <Typography color="error" variant="caption">
                {error}
              </Typography>
            </FlexRow>
          ) : null}
          <Button
            disabled={files.length === 0}
            color="secondary"
            fullWidth
            onClick={onCompleteClicked}
            variant="contained"
          >
            Complete
          </Button>
        </FlexGrowCol>
      </FlexRow>
      <MessageDialog onClose={onThankyouClose} open={thankyouOpen} title={'Images Submitted'}>
        <Typography variant="body1">
          Thank You! Your images should be reviewed in the next 24 to 72 hours. You will receive your COIN as a Geodrop
          in the COIN App once processed.
        </Typography>
      </MessageDialog>
    </BasePage>
  )
}

export default Page
