/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stepper } from '@mui/material'
import { useUserEvents } from '@xylabs/sdk-coin-react'
import React, { useState } from 'react'

import AccountSource from './AccountSource'
import { ActionStep, ScoreStep, SourceStep } from './Steps'

interface Props {
  buytickets?: boolean
  funnelName: string
  onDone?: () => void
  share?: boolean
}

enum Steps {
  Score = 0,
  Source = 1,
  Action = 2,
  Done = 3,
}

const FunnelStepper: React.FC<Props> = (props) => {
  const { onDone, funnelName } = props

  const [activeStep, setActiveStep] = useState(0)
  const [score, setScore] = useState<number>()
  const [source, setSource] = useState<AccountSource>()
  const userEvents = useUserEvents()

  const onScoreContinue = (score: number) => {
    setScore(score)
    userEvents.feedbackScoreCollected({ score })
    setActiveStep(Steps.Source)
  }

  const onSourceContinue = (source: AccountSource) => {
    setSource(source)
    userEvents.feedbackSourceCollected({ source })
    setActiveStep(Steps.Action)
  }

  const onActionContinue = () => {
    setActiveStep(Steps.Done)
    onDone?.()
  }

  return (
    <Stepper activeStep={activeStep} orientation="vertical" style={{ flexGrow: 1 }}>
      <ScoreStep onContinue={onScoreContinue} stepName="Step 1" subtitle="Would you recommend COIN to your friends?" />
      <SourceStep onContinue={onSourceContinue} stepName="Step 2" subtitle="Where did you hear about COIN?" />
      <ActionStep
        onContinue={onActionContinue}
        score={score}
        source={source}
        stepName="Feedback"
        subtitle="Provide feedback (click button):"
      />
    </Stepper>
  )
}

export default FunnelStepper
