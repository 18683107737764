import { Typography } from '@mui/material'
import { useAuthUser } from '@xylabs/sdk-coin-react'
import { FlexGrowCol, FlexGrowRow, MessageDialog } from '@xyo-network/sdk-xyo-react-js'
import JsonView from 'react-json-view'

import BasePage from '../../BasePage'
import { useCloudDashboardStats } from '../Hooks'

const Json: React.FC = () => {
  const user = useAuthUser()

  const { stats, error } = useCloudDashboardStats()

  const breadcrumbs = [user?.displayName ?? 'User', 'COIN', 'Stats', 'Json']

  return (
    <BasePage authOnly devOnly container="xl" breadcrumbs={breadcrumbs}>
      <FlexGrowCol padding={2} justifyContent="flex-start" alignItems="stretch">
        <FlexGrowRow paper padding={2} busy={!stats}>
          {stats ? (
            <JsonView
              sortKeys
              collapseStringsAfterLength={96}
              src={stats}
              style={{ overflow: 'hidden', width: '100%' }}
            />
          ) : null}
        </FlexGrowRow>
      </FlexGrowCol>
      <MessageDialog title={error?.message ?? 'Error'} open={!!error}>
        <Typography>Oops. Something went wrong. Please try again in a few minutes.</Typography>
      </MessageDialog>
    </BasePage>
  )
}

export default Json
