import { LocalOffer, Receipt } from '@mui/icons-material'
import { CoinRedeemSku } from '@xylabs/sdk-coin-js'

import OfferProps from '../../OfferProps'

const defaultOffers: OfferProps[] = [
  {
    claimButtonText: 'Get FREE SentinelX',
    current: true,
    description:
      'Boost your earnings on the COIN App using a SentinelX NFC. Use this offer to get it for free.  Just Pay Shipping.',
    href: 'https://coinapp.co/free/sentinelx/nfc',
    icon: <LocalOffer />,
    id: 'sentinelx',
    image: {},
    name: 'Free Sentinel X',
    sku: CoinRedeemSku.SentinelXNfc,
    tags: ['Free + Shipping'],
    title: 'SentinelX NFC',
  },
  {
    claimButtonText: 'Upgrade Now',
    current: true,
    description: 'Get the most out of your COIN App by adding a subscription.',
    href: 'https://my.coinapp.co/account',
    icon: <LocalOffer />,
    id: 'subscription',
    image: {},
    name: 'COIN Pro',
    sku: CoinRedeemSku.CoinPro,
    tags: ['Deal'],
    title: 'COIN Pro Subscription',
  },
  {
    claimButtonText: 'Provide Feedback',
    current: true,
    description: 'We want to know hoe you feel about your experience with COIN',
    icon: <Receipt />,
    id: 'feedback',
    image: {},
    name: 'COIN Feedback',
    sku: CoinRedeemSku.CoinFeedback,
    tags: ['Survey'],
    title: 'COIN Feedback Survey',
    to: '/feedback',
  },
]

export default defaultOffers
