import { Typography, useTheme } from '@mui/material'
import { FlexBoxProps, FlexCol } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

interface Props extends FlexBoxProps {
  image?: string
  textZoom?: number
  titleAlign?: 'left' | 'right' | 'center' | 'inherit'
}

const Hero: React.FC<Props> = ({
  children,
  image,
  title = 'Welcome to COIN!',
  alignItems,
  textZoom = 1,
  titleAlign = 'left',
  bgcolor,
  style,
  ...props
}) => {
  const theme = useTheme()

  return (
    <FlexCol
      bgcolor={bgcolor ?? theme.palette.background.paper}
      alignItems={alignItems}
      justifyContent="flex-start"
      color={theme.palette.getContrastText(`${bgcolor}`)}
      style={{
        ...style,
        backgroundImage: `url(${image})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
      }}
      {...props}
    >
      <FlexCol margin={4} alignItems={alignItems} style={{ zoom: textZoom }}>
        <Typography variant="h1" align={titleAlign}>
          {title}
        </Typography>
      </FlexCol>
      {children}
    </FlexCol>
  )
}

export default Hero
