import { useMediaQuery, useTheme } from '@mui/material'
import { isAuthenticated, useAuthUser, useUserEvents } from '@xylabs/sdk-coin-react'
import { FlexGrowCol, FlexGrowRow } from '@xyo-network/sdk-xyo-react-js'
import React, { useEffect } from 'react'

import BasePage from '../../BasePage'
import { OneColumnHero, TwoColumnHero } from './Hero'
import { CreateAccountInteractionArea, FreeEntriesSuccessInteractionArea } from './InteractionArea'

interface Props {
  funnelName: string
}

const Page: React.FC<Props> = (props) => {
  const { funnelName } = props
  const userEvents = useUserEvents()
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))
  const authenticated = isAuthenticated()
  const user = useAuthUser()

  useEffect(() => {
    userEvents.viewContent({ name: funnelName, path: document.location.href })
  }, [userEvents, funnelName])

  if (authenticated === undefined) {
    return <BasePage container="xl" disableGutters hideAppBar />
  }

  return (
    <BasePage hideAppBar>
      {desktop ? (
        <FlexGrowRow alignItems="stretch" minHeight={600}>
          <TwoColumnHero flexGrow={1} flexBasis={50} padding={2} bgcolor="#004299" />
          {authenticated ? (
            <FreeEntriesSuccessInteractionArea sku={funnelName} email={user?.email ?? ''} />
          ) : (
            <CreateAccountInteractionArea />
          )}
        </FlexGrowRow>
      ) : (
        <FlexGrowCol alignItems="stretch">
          <OneColumnHero paddingLeft={0.5} bgcolor="#004299" minHeight={260} />
          {authenticated ? (
            <FreeEntriesSuccessInteractionArea sku={funnelName} email={user?.email ?? ''} />
          ) : (
            <CreateAccountInteractionArea />
          )}
        </FlexGrowCol>
      )}
    </BasePage>
  )
}

export default Page
