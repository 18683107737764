import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  StepProps,
  Typography,
} from '@mui/material'
import { FlexCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React, { useState } from 'react'
import FacebookIcon from 'react-share/es/FacebookIcon'
import FacebookMessengerIcon from 'react-share/es/FacebookMessengerIcon'
import FacebookMessengerShareButton from 'react-share/es/FacebookMessengerShareButton'
import FacebookShareButton from 'react-share/es/FacebookShareButton'
import PinterestIcon from 'react-share/es/PinterestIcon'
import PinterestShareButton from 'react-share/es/PinterestShareButton'
import RedditIcon from 'react-share/es/RedditIcon'
import RedditShareButton from 'react-share/es/RedditShareButton'
import TwitterIcon from 'react-share/es/TwitterIcon'
import TwitterShareButton from 'react-share/es/TwitterShareButton'
import WhatsappIcon from 'react-share/es/WhatsappIcon'
import WhatsappShareButton from 'react-share/es/WhatsappShareButton'

interface Props extends StepProps {
  entryBoostAccepted?: boolean
  entrySelection?: number
  onContinue?: () => void
  stepName?: string
  subtitle?: string
}

const CustomStep: React.FC<Props> = (props) => {
  const { onContinue, stepName = 'Share This Offer With Friends', subtitle = '', ...stepProps } = props
  const [shared, setShared] = useState(false)
  const [entrySelection, setEntrySelection] = useState(10)

  const onLocalContinue = () => {
    onContinue?.()
  }

  const onShareWindowClose = () => {
    setShared(true)
    setEntrySelection(100)
  }

  const title = 'I Just Got my 100 Free Entries Entered to Win a Sony Playstation 5!'
  const hashtags = ['ps5', 'coinwithus', 'xyo', 'sweepstakes']
  const url = document.location.href
  const media = `https://${document.location.host}/img/ps5-share-landscape.jpg`
  const facebookAppId = '505073083355934'

  return (
    <Step {...stepProps}>
      <StepLabel>
        <Typography variant="h4">{stepName}</Typography>
      </StepLabel>
      <StepContent>
        <FlexCol alignItems="start" paddingY={1}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography variant="body1">{subtitle}</Typography>
            </FormLabel>
            <RadioGroup aria-label="entrySelection" name="entrySelection" value={entrySelection}>
              <FormControlLabel value={10} control={<Radio />} disabled={shared} label="10 Entries" />
              <FormControlLabel
                value={100}
                disabled={!shared}
                control={<Radio />}
                label="100 Entries (Unlock by sharing this offer)"
              />
            </RadioGroup>
          </FormControl>
        </FlexCol>
        <Typography variant="body1">Share with friends for 10x free entries:</Typography>
        <FlexRow justifyContent="space-around" margin={1} flexWrap="wrap">
          <FacebookShareButton
            quote={`${title} @coinwithus`}
            title={'Get 100 Free Entries for a PS5 Sweepstakes'}
            hashtag={hashtags[0]}
            url={url}
            onShareWindowClose={onShareWindowClose}
          >
            <FacebookIcon />
          </FacebookShareButton>
          <FacebookMessengerShareButton
            title={`${title} @coinwithus`}
            appId={facebookAppId}
            url={url}
            onShareWindowClose={onShareWindowClose}
          >
            <FacebookMessengerIcon />
          </FacebookMessengerShareButton>
          <TwitterShareButton
            url={url}
            title={`${title} @coin_with_us`}
            hashtags={hashtags}
            onShareWindowClose={onShareWindowClose}
          >
            <TwitterIcon />
          </TwitterShareButton>
          <RedditShareButton url={url} title={title} onShareWindowClose={onShareWindowClose}>
            <RedditIcon />
          </RedditShareButton>
          <WhatsappShareButton url={url} title={title} onShareWindowClose={onShareWindowClose}>
            <WhatsappIcon />
          </WhatsappShareButton>
          <PinterestShareButton media={media} url={url} onShareWindowClose={onShareWindowClose}>
            <PinterestIcon />
          </PinterestShareButton>
        </FlexRow>
        <FlexCol paddingBottom={1}>
          <Button disabled={shared} fullWidth variant="outlined" onClick={onLocalContinue} color="primary">
            Continue with 10 Entries
          </Button>
        </FlexCol>
        <Button disabled={!shared} fullWidth variant="contained" onClick={onLocalContinue} color="secondary">
          Continue with 100 Entries
        </Button>
      </StepContent>
    </Step>
  )
}

export default CustomStep
