import { Grid, GridProps, useTheme } from '@mui/material'
import { CoinSweepstakeJson } from '@xylabs/sdk-coin-js'
import { useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import React, { useState } from 'react'

import { useApi } from '../../../Contexts'
import offersFromSweepstakes from '../../offersFromSweepstakes'
import defaultOffers from './defaultOffers'
import { OfferCard } from './Offer'

const Offers: React.FC<GridProps> = (props) => {
  const api = useApi()
  const [sweepstakes, setSweepstakes] = useState<CoinSweepstakeJson[]>()
  const [apiError, setApiError] = useState<Error>()
  const theme = useTheme()

  useAsyncEffect(
    async (mounted) => {
      if (api && !apiError) {
        try {
          const sweepstakes = await api.getSweepstakes()
          if (mounted()) {
            setSweepstakes(sweepstakes)
          }
        } catch (ex) {
          const error = ex as Error
          setApiError(error)
        }
      }
    },
    [api, apiError]
  )

  const offers = sweepstakes ? offersFromSweepstakes(sweepstakes, true) : undefined
  const now = Date.now()

  return (
    <Grid container {...props}>
      {offers?.map((offer) => {
        return offer && (offer?.endTime?.toMillis() ?? 0) > now ? (
          <Grid item key={offer.id}>
            <OfferCard {...offer} style={{ margin: theme.spacing(1) }} />
          </Grid>
        ) : null
      })}
      {offers
        ? defaultOffers?.map((offer) => {
            return offer ? (
              <Grid item key={offer.id}>
                <OfferCard {...offer} style={{ margin: theme.spacing(1) }} />
              </Grid>
            ) : null
          })
        : null}
      {offers?.map((offer) => {
        return offer && (offer?.endTime?.toMillis() ?? 0) < now ? (
          <Grid item key={offer.id}>
            <OfferCard {...offer} style={{ margin: theme.spacing(1) }} />
          </Grid>
        ) : null
      })}
      <div />
    </Grid>
  )
}

export default Offers
