import { AuthContext } from '@xylabs/sdk-coin-react'
import { getAuth, onAuthStateChanged, User as FirebaseUser } from 'firebase/auth'
import React, { PropsWithChildren, useEffect, useState } from 'react'

interface Props {
  options?: Record<string, unknown>
}

const Provider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { options, children } = props
  const [user, setUser] = useState<FirebaseUser | null>()
  const [authenticated, setAuthenticated] = useState<boolean>()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user?: FirebaseUser | null) => {
      setAuthenticated(!!user)
      setUser(user)
    })

    return () => {
      unsubscribe()
    }
  }, [options])

  return <AuthContext.Provider value={{ auth: getAuth(), authenticated, user }}>{children}</AuthContext.Provider>
}

export default Provider
