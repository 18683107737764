import { useTheme } from '@mui/material'
import { PixelApi, XyPixel } from '@xylabs/pixel'
import { themeOptions } from '@xylabs/sdk-coin-react'
import {
  CookieConsent,
  Fbq,
  FlexGrowCol,
  Gtag,
  InvertableThemeProvider,
  Rdt,
  SnapTr,
  Ttq,
} from '@xyo-network/sdk-xyo-react-js'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { ApiProvider, AuthProvider, PixelProvider, RebillyApiProvider, SettingsLoader, useSettings } from './Contexts'
import {
  AccountPages,
  FeedbackPages,
  HomePages,
  LegalPages,
  OffersPages,
  SettingsPages,
  StartPages,
  UserPages,
} from './Pages'

const initXyPixel = () => {
  switch (document.location.hostname) {
    case 'localhost':
      XyPixel.selectApi(new PixelApi('local'))
      break
    case 'beta.coinapp.co':
      XyPixel.selectApi(new PixelApi('beta'))
      break
    default:
      XyPixel.selectApi(new PixelApi('prod'))
      break
  }
  XyPixel.init(document.location.hostname)
}

const initPixels = () => {
  try {
    initXyPixel()
    Fbq.init('339003476728548')
    Gtag.init('G-TCJLRQCJ2H', 'AW-989965544', ['coinapp.co', 'localhost', 'localhost:3000'])
    Ttq.init('C1EJK2K8PMMOGUUN29P0')
    Rdt.init('t2_3qsbqscf')
    SnapTr.init('29bf72a5-b8bd-4601-85bc-847bf293f17a')
  } catch (ex) {
    console.error(`initPixels: ${ex}`)
  }
}

import { initializeApp } from 'firebase/app'

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
})

const AppBody: React.FC = () => {
  const theme = useTheme()
  return (
    <RebillyApiProvider>
      <PixelProvider id="app.coinapp.co">
        <Router>
          <FlexGrowCol
            width="100vw"
            minHeight="100vh"
            justifyContent="flex-start"
            alignContent="stretch"
            bgcolor={theme.palette.background.default}
            color={theme.palette.text.primary}
          >
            <Switch>
              <Route component={AccountPages} path="/account" />
              <Route component={LegalPages} path="/legal" />
              <Route component={SettingsPages} path="/settings" />
              <Route component={FeedbackPages} path="/feedback" />
              <Route component={StartPages} path="/start" />
              <Route component={UserPages} path="/u" />
              <Route component={OffersPages} path="/offers" />
              <Route component={HomePages} path="/" />
              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
            <CookieConsent />
          </FlexGrowCol>
        </Router>
      </PixelProvider>
    </RebillyApiProvider>
  )
}

const AppThemeBody: React.FC = () => {
  const { darkMode } = useSettings()
  return (
    <InvertableThemeProvider dark={darkMode} options={themeOptions}>
      <AppBody />
    </InvertableThemeProvider>
  )
}

const App: React.FC = () => {
  initPixels()
  return (
    <>
      <Helmet defaultTitle="COIN App" titleTemplate="%s | COIN App">
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-TCJLRQCJ2H" />
        <script async src="https://connect.facebook.net/en_US/fbevents.js" />
        <script async src="https://sc-static.net/scevent.min.js" />
      </Helmet>
      <SettingsLoader>
        <AuthProvider>
          <ApiProvider>
            <InvertableThemeProvider options={themeOptions}>
              <AppThemeBody />
            </InvertableThemeProvider>
          </ApiProvider>
        </AuthProvider>
      </SettingsLoader>
    </>
  )
}

export default App
