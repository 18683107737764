import { BoxProps, Typography } from '@mui/material'
import { FlexRow } from '@xyo-network/sdk-xyo-react-js'

import { DownloadButtonBox } from '../../../../Components'

const Upsell: React.FC<BoxProps> = (props) => {
  return (
    <FlexRow {...props}>
      <Typography variant="body1" align="center">
        Looks like you&apos;re all done! Did you download COIN to collect your free entries?
      </Typography>
      <DownloadButtonBox
        iosLink="https://coin.onelink.me/ePJg/262cfa7c"
        androidLink="https://coin.onelink.me/ePJg/262cfa7c"
      />
    </FlexRow>
  )
}

export default Upsell
