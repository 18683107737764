import { CoinSweepstakeHistoryJson, CoinUserSweepstakesSummaryJson } from '@xylabs/sdk-coin-js'
import { useAuthUser } from '@xylabs/sdk-coin-react'
import { FlexGrowCol, FlexGrowRow, useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import { useState } from 'react'
import JsonView from 'react-json-view'

import { useApi } from '../../../Contexts'
import BasePage from '../../BasePage'

const Json: React.FC = () => {
  const api = useApi()
  const user = useAuthUser()

  const [stats, setStats] = useState<CoinUserSweepstakesSummaryJson>()
  const [sweepstakes, setSweepstakes] = useState<CoinSweepstakeHistoryJson[]>()

  const breadcrumbs = [user?.displayName ?? 'User', 'Sweepstakes', 'Json']

  useAsyncEffect(
    async (mounted) => {
      if (api?.authenticated) {
        const stats = await api.getUserSweepstakesSummary()
        const sweepstakes = await api.getUserSweepstakes()
        if (mounted()) {
          setStats(stats)
          setSweepstakes(sweepstakes)
        }
      }
    },
    [api?.authenticated]
  )

  return (
    <BasePage authOnly devOnly container="xl" breadcrumbs={breadcrumbs}>
      <FlexGrowCol padding={2} justifyContent="flex-start" alignItems="stretch" busy={!(api && stats && sweepstakes)}>
        <FlexGrowRow paper padding={2}>
          {stats ? (
            <JsonView
              collapseStringsAfterLength={96}
              src={{ stats, sweepstakes }}
              style={{ overflow: 'hidden', width: '100%' }}
            />
          ) : null}
        </FlexGrowRow>
      </FlexGrowCol>
    </BasePage>
  )
}

export default Json
