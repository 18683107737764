import { Typography } from '@mui/material'
import { useUserEvents } from '@xylabs/sdk-coin-react'
import { FlexCol, FlexGrowCol, FlexGrowRow, FlexRow, InvertableThemeProvider } from '@xyo-network/sdk-xyo-react-js'
import React, { useEffect, useState } from 'react'

import BasePage from '../BasePage'
import FunnelStepper from './FunnelStepper'
import offerImage from './img/xyo-tokens/320x180.jpg'

const Page: React.FC = () => {
  const funnelName = 'feedback-general'
  const offerTitle = 'Your Feedback = 500 COIN'
  const userEvents = useUserEvents()
  const [done, setDone] = useState(false)

  useEffect(() => {
    userEvents.viewContent({ name: funnelName, path: document.location.href })
  }, [userEvents, funnelName])

  const onDone = () => {
    setDone(true)
  }

  return (
    <BasePage container="sm">
      <FlexRow paper marginY={2}>
        <FlexGrowCol alignItems="stretch" marginY={2} marginX={1}>
          <FlexGrowCol marginY={2}>
            <FlexRow>
              <img src={offerImage} width="70%" height="auto" />
            </FlexRow>
            <FlexRow paddingY={1}>
              <Typography variant="h3" align="center">
                {offerTitle}
              </Typography>
            </FlexRow>
          </FlexGrowCol>
          <FlexGrowRow margin={2}>
            <FunnelStepper funnelName={funnelName} share onDone={onDone} />
          </FlexGrowRow>
          <FlexCol display={done ? 'flex' : 'none'} marginY={2} alignItems="stretch">
            <InvertableThemeProvider invert={true}>
              <FlexRow paper margin={1} padding={2}>
                <Typography variant="body1">Thank you!</Typography>
              </FlexRow>
            </InvertableThemeProvider>
          </FlexCol>
        </FlexGrowCol>
      </FlexRow>
    </BasePage>
  )
}

export default Page
