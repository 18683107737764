import { BoxProps, Link, Toolbar, ToolbarProps, Typography } from '@mui/material'
import { isAuthenticated, useAuthUser } from '@xylabs/sdk-coin-react'
import {
  BasePage,
  BasePageProps,
  BreadcrumbsEx,
  ButtonEx,
  FlexCol,
  FlexGrowCol,
  FlexRow,
  InvertableThemeProvider,
  isLocalhost,
} from '@xyo-network/sdk-xyo-react-js'
import React, { PropsWithChildren, ReactElement } from 'react'

import { SigninAccountBox } from '../Components'
import { useSettings } from '../Contexts'
import { Footer } from '../Footer'
import { HeaderAppBar } from '../Header'
import comingSoonScene from './img/coming-soon-scene.svg'
import logo from './img/logo-full.svg'

interface CoinBasePageProps extends BasePageProps {
  authOnly?: string | boolean
  breadcrumbs?: string[]
  devOnly?: string | boolean
  devOnlyTo?: string | boolean
  hideSystemToolbar?: boolean
  localAsDev?: boolean
  toolbar?: ReactElement<ToolbarProps>
}

const CoinBasePage: React.FC<PropsWithChildren<CoinBasePageProps>> = (props) => {
  const {
    breadcrumbs = [],
    hideSystemToolbar,
    devOnly = false,
    devOnlyTo = false,
    localAsDev = false,
    authOnly = false,
    children,
    toolbar,
    ...rootProps
  } = props

  const { developerMode } = useSettings()

  const authenticated = isAuthenticated()
  const user = useAuthUser()

  const devRequirementPassed = !(devOnly || devOnlyTo) || developerMode || (localAsDev && isLocalhost)
  const authRequirementPassed = !authOnly || authenticated

  const ComingSoon: React.FC<BoxProps> = (props) => {
    return (
      <FlexCol {...props} padding={2}>
        <img src={comingSoonScene} width="auto" height={320} />
        <Typography variant="h1">COIN App for Mobile Web coming soon!</Typography>
        <FlexCol margin={2}>
          <Typography>
            We’re still in progress. Check back or follow us on socials for updates! In the meantime, earn COIN by
            downloading the app.
          </Typography>
          <Typography>
            In the meantime, earn COIN by <Link href="https://coin.onelink.me/ePJg/35eb628c">downloading</Link> the app.
          </Typography>
        </FlexCol>
        <ButtonEx margin={1} color="secondary" variant="contained" href="https://coin.onelink.me/ePJg/35eb628c">
          Download the COIN App
        </ButtonEx>
        <Typography>or</Typography>
        <Typography>
          Visit <Link href="https://coinapp.co">coinapp.co</Link> for more information about the COIN App
        </Typography>
        <Typography>or</Typography>
        <Typography>
          Visit <Link href="https://my.coinapp.co">my.coinapp.co</Link> for all your COIN Account Settings
        </Typography>
      </FlexCol>
    )
  }

  const NeedsAuthentication: React.FC<BoxProps> = (props) => {
    return (
      <FlexCol padding={2} {...props}>
        <SigninAccountBox title="Sign In" marginY={1} minWidth={320} />
      </FlexCol>
    )
  }

  if (!authRequirementPassed) {
    if (user === undefined) {
      return <FlexGrowCol busy />
    }
  }

  const DefaultToolbar: React.FC = () => {
    return (
      <Toolbar disableGutters>
        <BreadcrumbsEx logo={logo} titles={breadcrumbs} path={breadcrumbs.length > 0 ? undefined : '/'} />
      </Toolbar>
    )
  }

  return (
    <BasePage
      appBar={
        <InvertableThemeProvider dark>
          <HeaderAppBar hideSystemToolbar={hideSystemToolbar} toolbar={toolbar ?? <DefaultToolbar />} />
        </InvertableThemeProvider>
      }
      appFooter={
        <InvertableThemeProvider dark>
          <Footer />
        </InvertableThemeProvider>
      }
      {...rootProps}
    >
      {devOnly ? (
        <FlexRow margin={1}>
          <Typography variant="body1">
            Important: This page is a Developer Only page. It is possible that some information may not be correct.
          </Typography>
        </FlexRow>
      ) : null}
      {authRequirementPassed ? (
        devRequirementPassed ? (
          children
        ) : (
          <ComingSoon width="100%" />
        )
      ) : (
        <NeedsAuthentication width="100%" />
      )}
    </BasePage>
  )
}

export default CoinBasePage
