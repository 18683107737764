import { Box, colors, FormControl, Typography } from '@mui/material'
import React, { useState } from 'react'

import CreditCardInput from './CreditCardInput'
import { CreditCardLogos } from './CreditCardLogos'

interface Props {
  hideError?: boolean
  hideLogos?: boolean
  onChange?: (creditcard: string, expiry: string, cvc: string, valid: boolean) => void
  onError?: (error?: string, inputName?: string) => void
}

const CreditCard: React.FC<Props> = (props) => {
  const { onChange, onError, hideError, hideLogos } = props
  const [creditcard, setCreditcard] = useState('')
  const [cvc, setCvc] = useState('')
  const [expiry, setExpiry] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>()

  const onCardNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const creditcard = event.target.value
    setCreditcard(creditcard)
    onChange?.(creditcard, expiry, cvc, errorMessage === undefined)
  }

  const onExpiryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const expiry = event.target.value
    setExpiry(expiry)
    onChange?.(creditcard, expiry, cvc, errorMessage === undefined)
  }

  const onCvcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cvc = event.target.value
    setCvc(cvc)
    onChange?.(creditcard, expiry, cvc, errorMessage === undefined)
  }

  return (
    <FormControl fullWidth>
      <Box display="flex" flexDirection="column">
        <Box alignItems="center" border={1} borderColor={colors.grey[500]} borderRadius={5} display="flex">
          <CreditCardInput
            hideError={!!hideError}
            cardCVCInputProps={{
              autoComplete: 'cc-csc',
              autoFocus: creditcard.length > 0 && expiry.length > 0 && cvc.length === 0,
              name: 'cvc',
              onChange: onCvcChange,
              value: cvc,
            }}
            cardExpiryInputProps={{
              autoComplete: 'cc-exp',
              autoFocus: creditcard.length > 0 && expiry.length === 0,
              name: 'cc-exp',
              onChange: onExpiryChange,
              value: expiry,
            }}
            cardNumberInputProps={{
              autoComplete: 'cc-number',
              autoFocus: creditcard.length === 0,
              name: 'cardnumber',
              onChange: onCardNumberChange,
              value: creditcard,
            }}
            onError={(error?: string, inputName?: string) => {
              setErrorMessage(error)
              onError?.(error, inputName)
            }}
          />
          {!hideLogos ? <CreditCardLogos /> : null}
        </Box>
        {!hideError ? (
          <Box height="1em">
            <Typography color="error" variant="caption">
              {errorMessage}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </FormControl>
  )
}

export default CreditCard
