import { BoxProps, Checkbox, FormControl, FormControlLabel, TextField, Typography } from '@mui/material'
import { isAuthenticated, useAuth } from '@xylabs/sdk-coin-react'
import { delay } from '@xyo-network/sdk-xyo-js'
import { ButtonEx, CoverProgress, FlexCol, FlexGrowRow, FlexRow, useMounted } from '@xyo-network/sdk-xyo-react-js'
import { signInWithEmailAndPassword } from 'firebase/auth'
import React, { ChangeEvent, useState } from 'react'

const SigninAccountBox: React.FC<BoxProps> = (props) => {
  const auth = useAuth()
  const authenticated = isAuthenticated()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPasswordChecked, setShowPasswordChecked] = useState(false)
  const [error, setError] = useState('')
  const [busy, setBusy] = useState(false)

  const isMounted = useMounted()

  const onSignIn = async () => {
    if (auth) {
      try {
        setBusy(true)
        await Promise.all([signInWithEmailAndPassword(auth, email, password), delay(500)])
      } catch (ex) {
        const error = ex as Error
        setError(error.message ?? error.toString())
        if (isMounted()) {
          setBusy(false)
        }
      } finally {
        if (isMounted()) {
          setBusy(false)
        }
      }
    }
  }
  const onEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(event.target.value)
  }
  const onPasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value)
  }

  const onShowPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPasswordChecked(event.target.checked)
  }

  if (authenticated === undefined) {
    return <CoverProgress />
  }

  return (
    <FlexCol alignItems="stretch" busy={busy} {...props}>
      <FlexRow margin={2} justifyContent="flex-start">
        <Typography variant="h3">Sign In</Typography>
      </FlexRow>
      <FlexRow margin={2}>
        <TextField
          id="email"
          label="Email"
          onChange={onEmailChange}
          type="text"
          value={email}
          variant="outlined"
          fullWidth
        />
      </FlexRow>
      <FlexCol margin={2} alignItems="flex-start">
        <TextField
          id="password"
          label="Password"
          onChange={onPasswordChange}
          type={showPasswordChecked ? 'text' : 'password'}
          value={password}
          variant="outlined"
          fullWidth
        />
        <FormControl>
          <FormControlLabel
            control={<Checkbox checked={showPasswordChecked} name="showPassword" onChange={onShowPasswordChange} />}
            label="Show Password"
          />
        </FormControl>
      </FlexCol>
      <FlexRow minHeight={20} margin={1}>
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      </FlexRow>
      <FlexGrowRow margin={2} justifyContent="flex-start">
        <ButtonEx paddingX={4} onClick={onSignIn} variant="contained" color="secondary">
          Sign In
        </ButtonEx>
        <ButtonEx marginLeft={1} marginY={1} paddingX={4} to="/account/signup" variant="text" color="inherit">
          Sign Up
        </ButtonEx>
      </FlexGrowRow>
    </FlexCol>
  )
}

export default SigninAccountBox
