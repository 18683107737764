import { Typography } from '@mui/material'
import { CoinSweepstakeJson } from '@xylabs/sdk-coin-js'
import { ErrorDialog, FlexGrowCol, FlexRow, useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import React, { useState } from 'react'

import { useApi } from '../../Contexts'
import BasePage from '../BasePage'
import offersFromSweepstakes from '../offersFromSweepstakes'
import { OffersGrid } from './Components'

const Page: React.FC = () => {
  const api = useApi()
  const [sweepstakes, setSweepstakes] = useState<CoinSweepstakeJson[]>()
  const [apiError, setApiError] = useState<Error>()

  useAsyncEffect(
    async (mounted) => {
      if (api && !apiError) {
        try {
          console.log('getting3a')
          const sweepstakes = await api.getSweepstakes()
          console.log(`getting3: ${JSON.stringify(sweepstakes)}`)
          if (mounted()) {
            console.log(`setSweepstakes: ${JSON.stringify(sweepstakes)}`)
            setSweepstakes(sweepstakes)
          }
        } catch (ex) {
          const error = ex as Error
          console.log(`Errors: ${ex}`)
          setApiError(error)
        }
      }
    },
    [api, apiError]
  )

  const offers = sweepstakes ? offersFromSweepstakes(sweepstakes, true) : undefined

  const breadcrumbs = ['Offers']

  return (
    <BasePage title="Home" container="xl" breadcrumbs={breadcrumbs}>
      <FlexGrowCol marginY={2} justifyContent="flex-start" alignItems="center" busy={!offers} busyOpacity={1}>
        <FlexRow justifyContent="flex-start" marginY={1}>
          <Typography variant="h1">Available Offers and Sweepstakes</Typography>
        </FlexRow>
        <OffersGrid />
      </FlexGrowCol>
      <ErrorDialog error={apiError} onClose={() => setApiError(undefined)} />
    </BasePage>
  )
}

export default Page
