import { SweepstakesSummaryContext } from '@xylabs/sdk-coin-react'
import { useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import React, { PropsWithChildren, useState } from 'react'

import { useApi } from './Api'

const Provider: React.FC<PropsWithChildren<unknown>> = (props) => {
  const [totalEntries, setTotalEntries] = useState<number>()
  const [refreshCount, setRefreshCount] = useState(0)
  const [error, setError] = useState<Error>()
  const api = useApi()
  useAsyncEffect(
    async (mounted) => {
      if (mounted()) {
        setTotalEntries(undefined)
        setError(undefined)
      }
      if (api && api.authenticated) {
        try {
          const sweepstakesSummary =
            refreshCount === 0 ? await api.getUserSweepstakesSummary() : await api.fetchUserSweepstakesSummary()
          if (mounted()) {
            setTotalEntries(sweepstakesSummary.total_entries)
          }
        } catch (ex) {
          const error = ex as Error
          if (mounted()) {
            setError(error)
          }
        }
      }
    },
    [api, refreshCount]
  )

  const refresh = () => {
    setRefreshCount(refreshCount + 1)
  }

  return <SweepstakesSummaryContext.Provider value={{ error, refresh, totalEntries }} {...props} />
}

export default Provider
