import { BoxProps } from '@mui/material'
import { FlexGrowRow } from '@xyo-network/sdk-xyo-react-js'
import JsonView from 'react-json-view'

interface Props extends BoxProps {
  json?: Record<string, unknown>
  open?: boolean
}

const JsonViewer: React.FC<Props> = (props) => {
  const { json = {}, open, ...boxProps } = props
  return open ? (
    <FlexGrowRow {...boxProps}>
      <JsonView
        collapseStringsAfterLength={64}
        src={json}
        style={{ overflow: 'hidden', padding: '8px', width: '100%' }}
        theme="summerfruit"
      />
    </FlexGrowRow>
  ) : null
}

export default JsonViewer
