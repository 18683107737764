import { BoxProps, useMediaQuery, useTheme } from '@mui/material'
import { FlexCol, FlexGrowRow, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import { CreateAccountBox, WhatIsCoin } from '../../../Components'
import logo from '../img/logo-full.svg'

const CreateAccount: React.FC<BoxProps> = (props) => {
  const { ...boxProps } = props
  const theme = useTheme()
  const logoSize = useMediaQuery(theme.breakpoints.up('md')) ? 64 : 48
  return (
    <FlexCol flexGrow={1} flexBasis={50} alignItems="stretch" padding={1} {...boxProps}>
      <FlexRow justifyContent="flex-start" margin={1} marginY={3}>
        <img src={logo} title="COIN Logo" height={logoSize} />
      </FlexRow>
      <CreateAccountBox title="Sign Up" marginY={2} maxWidth={600} />
      <FlexGrowRow />
      <WhatIsCoin />
    </FlexCol>
  )
}

export default CreateAccount
