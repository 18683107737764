import { Receipt } from '@mui/icons-material'
import { CoinSweepstakeJson } from '@xylabs/sdk-coin-js'
import { DateTime } from 'luxon'

import OfferProps from './OfferProps'
import SkuToImageSet from './SkuToImageSet'

const offersFromSweepstakes = (sweepstakes: CoinSweepstakeJson[], icons = false): (OfferProps | null)[] => {
  const offers = sweepstakes.map<OfferProps | null>((sweepstake) => {
    const sku = sweepstake.sku
    if (!sku) {
      return null
    }
    return {
      claimButtonText: 'Claim',
      current: !(sweepstake.winners && sweepstake.winners?.length > 0),
      description: sweepstake.subtitle || '',
      endTime: sweepstake.endDate ? DateTime.fromISO(sweepstake.endDate) : undefined,
      href: `https://app.coinapp.co/start/${sku}`,
      icon: icons ? <Receipt /> : null,
      id: sweepstake.id,
      image: SkuToImageSet[sku],
      name: sweepstake.item,
      sku,
      tags: ['Sweepstakes'],
      title: sweepstake.title,
      to: `/start/${sku}`,
      winner: sweepstake.winners?.[0]?.confirmed ? sweepstake.winners?.[0]?.username : 'Pending',
      winnerImage: sweepstake.winners?.[0]?.confirmed ? sweepstake.winners?.[0]?.profileImage : undefined,
    }
  })
  return offers
}

export default offersFromSweepstakes
