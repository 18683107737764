import { Avatar, BoxProps, Typography, useTheme } from '@mui/material'
import { FlexCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

interface Props extends BoxProps {
  textMaxWidth?: string | number
}

const HeroContent: React.FC<Props> = (props) => {
  const theme = useTheme()
  const { textMaxWidth = '60%', ...rootProps } = props
  return (
    <FlexCol alignItems="flex-start" {...rootProps}>
      <FlexRow marginX={1} marginBottom={1} justifyContent="flex-start">
        <Typography variant="subtitle2">100 Free Chances to Win a Sony Playstation 5 in the COIN App</Typography>
      </FlexRow>
      <FlexRow margin={1} maxWidth={textMaxWidth} justifyContent="flex-start" alignItems="flex-start">
        <Avatar
          style={{
            background: theme.palette.secondary.main,
            color: theme.palette.text.secondary,
            height: '2rem',
            width: '2rem',
          }}
        >
          <Typography variant="body1">1</Typography>
        </Avatar>
        <FlexRow marginX={1} marginY={0.5}>
          <Typography variant="body1">Sign Up</Typography>
        </FlexRow>
      </FlexRow>
      <FlexRow margin={1} maxWidth={textMaxWidth} justifyContent="flex-start" alignItems="flex-start">
        <Avatar
          style={{
            background: theme.palette.secondary.main,
            color: theme.palette.text.secondary,
            height: '2rem',
            width: '2rem',
          }}
        >
          <Typography variant="body1">2</Typography>
        </Avatar>
        <FlexRow marginX={1} marginY={0.5}>
          <Typography variant="body1">Download COIN App</Typography>
        </FlexRow>
      </FlexRow>
      <FlexRow margin={1} maxWidth={textMaxWidth} justifyContent="flex-start" alignItems="flex-start">
        <Avatar
          style={{
            background: theme.palette.secondary.main,
            color: theme.palette.text.secondary,
            height: '2rem',
            width: '2rem',
          }}
        >
          <Typography variant="body1">3</Typography>
        </Avatar>
        <FlexCol marginX={1} marginY={0.5} alignItems="flex-start">
          <Typography variant="body1">Receive 100 Free Entries (in-app)</Typography>
        </FlexCol>
      </FlexRow>
    </FlexCol>
  )
}

export default HeroContent
