import { Box, IconButton, Toolbar } from '@mui/material'
import { isAuthenticated, useAuthUser } from '@xylabs/sdk-coin-react'
import { AiOutlineLogin, AiOutlineLogout, AiOutlineSetting, AiOutlineUser } from 'react-icons/ai'
import { Link as RouterLink } from 'react-router-dom'

import { useSettings } from '../Contexts'

const SystemToolBar: React.FC = () => {
  const { denseMode } = useSettings()
  const currentUser = useAuthUser()
  const authenticated = isAuthenticated()
  if (authenticated !== undefined) {
    return (
      <Toolbar disableGutters variant={denseMode ? 'dense' : 'regular'}>
        <Box alignItems="right" display="flex" flexDirection="row">
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/u" title="Account">
              <AiOutlineUser size={24} />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/settings" title="Settings">
              <AiOutlineSetting size={24} />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'flex' : 'none'} padding={1}>
            <IconButton component={RouterLink} to="/account/signout" title="Sign Out">
              <AiOutlineLogout size={24} />
            </IconButton>
          </Box>
          <Box alignItems="center" display={currentUser ? 'none' : 'flex'} padding={1}>
            <IconButton component={RouterLink} to="/account/signin" title="Sign In">
              <AiOutlineLogin size={24} />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    )
  } else {
    return <Toolbar disableGutters variant={denseMode ? 'dense' : 'regular'} />
  }
}

export default SystemToolBar
