import { Tooltip, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import React from 'react'

interface TimerProps {
  prefix?: string
  time?: number
}

const Timer: React.FC<TimerProps> = (props) => {
  const { time, prefix } = props

  const datetime = time ? DateTime.fromMillis(time) : undefined

  const endTimeString = time ? DateTime.fromMillis(time).toLocaleString(DateTime.DATETIME_FULL) : undefined

  return (
    <Tooltip title={`${prefix}: ${endTimeString}`}>
      <Typography noWrap={true} variant="subtitle2">
        {prefix} {datetime?.toRelative() ?? null}
      </Typography>
    </Tooltip>
  )
}

export default Timer
